<template>
    <div>
        Hello Design
    </div>
</template>



<script>


export default {
    data(){
        return{
            designList: []
        }
    },
    methods:{
        getDesignList(){
            this.$Design.getDesignList().then(data=>{
                console.log(data.data);
            }).catch(error=>{
                console.log(error);
            })
        }
    },
    

    mounted(){
        this.getDesignList();
    }
}
</script>